<template>
  <div class="Mine">
    <div class="userName">WELCOME,{{ userInfo.email }}</div>
    <div class="cate">
      <div class="nav">
        <div
          :class="`item ${
            $route.path == '/OrderList' || $route.path == '/OrderDetail' ? 'active' : ''
          }`"
          @click="$router.push('/OrderList')"
        >
          {{ $t("userTab.order") }}
        </div>
        <div class="devide"></div>
        <div
          :class="`item ${$route.path == '/Information' ? 'active' : ''}`"
          @click="$router.push('/Information')"
        >
          {{ $t("userTab.setting") }}
        </div>
        <div class="devide"></div>
        <div
          :class="`item ${$route.path == '/Address' ? 'active' : ''}`"
          @click="$router.push('/Address')"
        >
          {{ $t("userTab.address") }}
        </div>
        <div class="devide"></div>
      </div>
      <div class="quit" @click="$bus.$emit('quit')">{{ $t("login.logoutTitle") }}</div>
    </div>
    <div style="padding: 0 62px 200px"><router-view /></div>
  </div>
</template>

<script>
export default {
  name: "Mine",
  data() {
    return {
      userInfo: {},
    };
  },
  created() {
    this.userInfo = this.$storage.get("user_info");
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.userName {
  font-size: 28px;
  font-weight: 600;
  color: #000000;
  height: 120px;
  line-height: 120px;
  text-align: center;
}
.cate {
  margin: 0 22px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #757575;
  border-bottom: 1px solid #757575;
  .nav {
    display: flex;
    align-items: center;
    .item {
      font-size: 14px;
      line-height: 56px;
      font-weight: 400;
      padding: 0 40px;
      position: relative;
      cursor: pointer;
      &.active::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 48px;
        right: 48px;
        height: 3px;
        background: #000000;
      }
    }
    .devide {
      width: 1px;
      height: 30px;
      background: #bababa;
    }
  }
  .quit {
    width: 90px;
    height: 34px;
    line-height: 34px;
    background: #ffffff;
    border: 1px solid #757575;
    font-size: 14px;
    font-weight: bold;
    margin-right: 40px;
    text-align: center;
    cursor: pointer;
    &:hover {
      background-color: #efefef;
    }
  }
}
@media screen and (max-width: 500px) {
  .userName {
    font-size: 6vw;
    height: 15vw;
    line-height: 15vw;
  }
  .cate {
    height: 12vw;
    margin: 0 3vw;
    .nav {
      .item {
        font-size: 3.2vw;
        padding: 0 5px;
        width: 20vw;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        height: 12vw;
        line-height: 13vw;
        text-align: center;
        &.active::after {
          height: 2px;
          left: 4vw;
          right: 4vw;
          bottom: 0;
        }
      }
      .devide {
        width: 1px;
        height: 4vw;
        background: #bababa;
      }
    }
    .quit {
      width: 20vw;
      margin-right: 0;
      height: 6vw;
      line-height: 7vw;
      font-size: 3vw;
      &:hover {
        background-color: #efefef;
      }
    }
    & + div {
      padding: 0 3vw 10vw !important;
    }
  }
}
</style>
